<ion-header>
  <ion-toolbar>
    <ion-title> Робоча зміна </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close" slot="icon-only"> </ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <div class="pt-4 pb-2">
    <div *ngIf="lastSession">
      <div class="card" style="position: relative">
        <span
          *ngIf="!lastSession.end"
          class="badge badge-success"
          style="
            position: absolute;
            font-size: 16px;
            font-weight: 500;
            top: -10px;
            left: 30px;
          "
        >
          {{ translate['state_open'] }}</span
        >
        <span
          *ngIf="lastSession.end"
          class="badge badge-secondary"
          style="
            position: absolute;
            font-size: 16px;
            font-weight: 500;
            top: -10px;
            left: 30px;
          "
        >
          {{ translate['state_closed'] }}</span
        >
        <div class="card-body">
          <div class="row">
            <div class="col">
              <div class="h4">
                {{ employeeName }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8">
              <div class="row">
                <div class="col-sm-6">{{ translate['date_start'] }}</div>
                <div class="col-sm-6">
                  {{
                    lastSession.start
                      ? (lastSession.start | date : 'dd.MM HH:mm')
                      : ''
                  }}
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">{{ translate['date_close'] }}</div>
                <div class="col-sm-6">
                  {{
                    lastSession.end
                      ? (lastSession.end | date : 'dd.MM HH:mm')
                      : ''
                  }}
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">{{ translate['duration'] }}</div>
                <div class="col-sm-6">
                  {{ duration }} {{ translate['hours'] }}
                </div>
              </div>
              <!-- <div class="row">
                <div class="col-sm-6">{{ translate['cashbox'] }}</div>
                <div class="col-sm-6">
                  {{ lastSession.cash }} / {{ lastSession.cashless }}
                  {{ translate['cash_bank'] }}
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">{{ translate['discount'] }}</div>
                <div class="col-sm-6">
                  {{ lastSession.discount }}
                </div>
              </div> -->
            </div>
            <div class="col-md-4">
              <div class="mb-4" *ngIf="!lastSession.end">
                <ion-button
                  class="w-100"
                  color="success"
                  (click)="selectSession(lastSession)"
                >
                  {{ translate['btn_continue_label'] }}
                </ion-button>
              </div>
              <div *ngIf="!lastSession.end">
                <ion-button class="w-100" (click)="closeSession()">
                  {{ translate['btn_close_label'] }}
                </ion-button>
              </div>
              <div *ngIf="lastSession.end" class="mb-4">
                <ion-button class="w-100" (click)="openSession()">
                  {{ translate['btn_open_label'] }}
                </ion-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>

<ion-footer
  *ngIf="(!lastSession || (lastSession && lastSession.end)) && !loading"
>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button
        fill="solid"
        color="success"
        (click)="startSession()"
        style="font-size: 22px; line-height: 50px"
      >
        {{ translate['btn_start_label'] }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>

<style>
  ::ng-deep .terminal-session-modal {
    --height: 390px;
  }
</style>
