import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { from } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import DateUtils from 'src/app/utility/date-utils';
import { EmployeeService } from './services/employee.service';
import { TerminalService } from './services/terminal.service';

@Component({
  templateUrl: './terminal-session-new.component.html',
})
export class TerminalSessionNewComponent {
  loading = false;
  lastSession: any;
  hasActiveSession = false;
  translate: any = {};
  employeeId: string;
  employeeName: string;
  storeId: number;
  duration: number;
  openActive = false;

  constructor(
    private _service: TerminalService,
    private _employeeService: EmployeeService,
    private _modalCtrl: ModalController
  ) {
    this._service
      .getTranslate('employee_shift')
      .subscribe((res) => (this.translate = res));
  }

  ngAfterViewInit() {
    this._loadSessions();
  }

  private _loadSessions() {
    this.loading = true;
    this._service
      .getEmployeeSessions(this.employeeId)
      .pipe(
        catchError(() => {
          return from(
            this._employeeService.getLastSession(this.employeeId)
          ).pipe(
            map((s) => {
              if (s) {
                return [s];
              } else {
                return null;
              }
            })
          );
        })
      )
      .subscribe(
        (res) => {
          this.loading = false;
          if (!res || res.length === 0) {
            return;
          }

          this.lastSession = res[0];
          let activeSession = null;
          if (!this.lastSession.end) {
            activeSession = res[0];

            const sessionDuration = DateUtils.differenceInHours(
              activeSession.start,
              activeSession.end || new Date()
            );

            if (sessionDuration < 5 && this.openActive) {
              this.closeModal(activeSession);
            }
          }
          this.getDuration(this.lastSession.start);
        },
        (err) => this.closeModal()
      );
  }

  selectSession(session: any) {
    this.closeModal(session);
  }

  closeSession = () => {
    this.loading = true;

    this._service
      .closeEmployeeSession(this.lastSession.uuid)
      .then(() => this.closeModal());

    // this._service.closeSession(sessionId).subscribe(
    //   () => {
    //     this.loading = false;
    //     this._loadSessions();
    //   },
    //   null,
    //   () => (this.loading = false)
    // );
  };

  startSession = () => {
    this.loading = true;

    console.log('this.storeId');
    console.log(this.storeId);

    this._employeeService.startSession(this.employeeId, this.storeId).then(
      (session) => {
        this.loading = false;
        this.closeModal(session);
      },
      (err) => {
        this.loading = false;
        //this.dialogRef.close();
      }
    );
  };

  getDuration(fromDate: Date) {
    const end = new Date();
    const duration = DateUtils.differenceInHours(fromDate, end);
    this.duration = Math.round(duration);
  }

  openSession = () => {
    const sessionId =
      this.lastSession.Id || this.lastSession.sessionId || this.lastSession.id;
    this.loading = true;
    console.log(this.lastSession);
    this._service.openSession(sessionId).subscribe({
      next: (session) => {
        this.loading = false;
        this.closeModal(session);
      },
      error: (err) => {
        this.loading = false;
        //this.dialogRef.close();
      },
    });
  };

  closeModal(data?: any) {
    this._modalCtrl.dismiss(data);
  }

  // openWorkShiftReport(workAreaId: number) {
  //     this._reportsService.openworkShiftReportDialog(workAreaId).subscribe();
  // }
}
